interface IDashboardMenus {
  title: string
  icon: string
  path: string
  description?: string
  roles?: string[]
  hidden?: boolean
}


export const SIDEBAR_EXPAND_WIDTH = 280;
export const SIDEBAR_COLLAPSED_WIDTH = 72;
export const APP_MENU: Record<string, { name: string, routes: IDashboardMenus[] }> = {
  main: {
    name: 'Menu',
    routes: [
      {
        title: 'Beranda',
        icon: 'LayoutGrid',
        path: '/home',
        roles: ['ADMIN_AFKAB', 'ADMIN_KLUB', 'ADMIN_KOMPETISI', 'ADMIN_APLIKASI'] 
      },
      {
        title: 'Lis Klub',
        icon: 'LayoutList',
        path: '/lis-klub',
        description: 'Manage Klub',
        roles: ['ADMIN_AFKAB', 'ADMIN_APLIKASI']
      },
      {
        title: 'My Klub',
        icon: 'Album',
        path: '/my-klub',
        description: 'Manage Klub',
        roles: ['ADMIN_KLUB']
      },
      {
        title: 'Lis Anggota',
        icon: 'Users',
        path: '/lis-anggota',
        description: 'List Anggota',
        roles: ['ADMIN_KLUB']
      },
      {
        title: 'Kompetisi',
        icon: 'Calendar',
        path: '/kompetisi',
        description: 'Manage Kompetisi',
        roles: ['ADMIN_AFKAB', 'ADMIN_APLIKASI', 'ADMIN_KOMPETISI', 'ADMIN_KLUB']
      },
      {
        path: '/pengurus-afkab',
        title: 'Pengurus Afkab',
        icon: 'User',
        description: 'Manage Pengurus',
        roles: ['ADMIN_AFKAB', 'ADMIN_APLIKASI', 'ADMIN_KOMPETISI', 'ADMIN_KLUB']
      },
      {
        path: '/user',
        title: 'User',
        icon: 'Users',
        description: 'Manage User',
        roles: ['ADMIN_AFKAB', 'ADMIN_APLIKASI']
      }
    ],
  }
};

