<script setup lang="ts">
import { Avatar, AvatarImage } from '@/components/ui/avatar';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import {
  LogOut,
  User,
  Menu,
} from 'lucide-vue-next'
import { useRouter } from 'vue-router'
import { Button } from '@/components/ui/button';
import { useAppStore } from '@/stores/app';
import { useProfileStore } from '@/stores/profile'
import { doLogout } from '@/lib/auth';

const router = useRouter()
const store = useAppStore();
const profile = useProfileStore()

// const toggleMode = () => {
//   store.toggleTheme();
// };

const handleLogout = async () => {
  await doLogout()
  router.replace('/login')
}
</script>

<template>
  <nav class="flex items-center justify-between h-[64px] px-4 fixed z-20 top-0 bg-background/80 backdrop-blur-lg shadow dark:border-b" :style="{ width: store.navWidth }">
    <div class="w-24 hidden lg:block"></div>
    <Button
      variant="outline"
      class="p-[6px] w-8 h-8 transition-all duration-200 block lg:hidden"
      :class="store.sidebarExpanded ? 'bg-transparent' : 'dark:bg-white'"
      @click="store.toggleSidebar()"
    >
      <Menu class="transition-all duration-500 text-black" />
    </Button>
    <div class="flex items-center">
      <!-- <Button variant="outline" class="border-0 p-[6px] ml-2 w-8 h-8" @click="toggleMode">
        <Sun v-if="store.isDark" />
        <MoonStar v-else />
      </Button>
      <div class="border-x-[1px] border-gray-300 h-[24px] w-[1px] mx-2"></div> -->
      <DropdownMenu>
        <DropdownMenuTrigger as-child>
          <Button variant="outline" class="border-0 flex items-center max-w-[200px] w-full justify-start">
            <Avatar class="h-8 w-8">
              <AvatarImage v-if="profile?.user?.klub" :src="profile?.user?.klub?.logo"></AvatarImage>
              <AvatarImage v-else src="https://github.com/radix-vue.png"></AvatarImage>
            </Avatar>
            <span class="ml-2 hidden md:flex justify-start flex-col items-start">
              <p class="mb-0">{{profile?.user?.user.nama}}</p>
              <small class="text-xs text-slate-400 font-light">{{profile?.user?.user.username}}</small>
            </span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent class="w-56 relative mr-4">
          <DropdownMenuLabel>{{profile?.user?.user.nama}}</DropdownMenuLabel>
          <DropdownMenuSeparator />
          <!-- <DropdownMenuItem>
            <User class="mr-2 h-4 w-4" />
            <span>Profile</span>
          </DropdownMenuItem> -->
          <DropdownMenuSeparator />
          <DropdownMenuItem class="cursor-pointer" @click="handleLogout()">
            <LogOut class="mr-2 h-4 w-4" />
            <span>Log out</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  </nav>
</template>
