import axios from 'axios'
import { useAuthStore } from '../stores/auth'
import { doRefresh } from '../lib/auth'

const apiConfigUrl = import.meta.env.VITE_API_URL || 'https://api.afkab-lotim.or.id'
const apiConfigTimeout = import.meta.env.VITE_API_TIMEOUT || 60
const apiGlobal = axios.create({ timeout: apiConfigTimeout * 1000 })
const apiRequest = axios.create({ baseURL: apiConfigUrl, timeout: apiConfigTimeout * 1000 })

apiRequest.interceptors.request.use(
	(config: any) => {
		const { authUser } = useAuthStore()
		const token = authUser?.access_token
		if (token) {
			config.headers['Authorization'] = `Bearer ${token}`
		}
		return config
	},
	(error) => Promise.reject(error)
)

// Response interceptor for API calls
apiRequest.interceptors.response.use(
	(response) => {
		return response
	},
	async function (error) {
		const originalRequest = error.config
		if ([401].includes(error.response.status) && !originalRequest._retry) {
			originalRequest._retry = true

			await doRefresh()

			return apiRequest(originalRequest)
		}
		return Promise.reject(error)
	}
)

const apiResult = async (output: any) => {
	const [error, result] = await output.then((res: any) => [null, res]).catch((err: any) => [err, null])
	if (error) {
		return {
			code: error?.response?.status,
			codeSystem: error?.code,
			message: error?.response?.statusText,
			messageSystem: error?.message,
			data: error?.response?.data
		}
	} else {
		return { code: result?.status, message: result?.statusText, data: result?.data }
	}
}

const httpCall = ({ url, method, params, data, mode = 'internal' }: HttpCall) => {
	const req = mode === 'internal' ? apiRequest({ url, method, params, data }) : apiGlobal({ url, method, params, data })
	const res = apiResult(req)
	return res
}

interface HttpCall {
  url: string,
  method: string,
  params?: string,
  data?: object,
  mode?: string
}

export { apiGlobal, apiRequest, apiResult, httpCall }
