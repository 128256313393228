import { defineStore } from 'pinia'

export const useProfileStore = defineStore({
	id: 'profile',
	state: () => {
		return {
			user:  null as any | null,
			differentUser: null as any | null
		}
	},
	getters: {
		getUser: (state) => state.user || null
	},
	actions: {
		setUser(value: any) {
			this.user = value
		},
		clear() {
			this.user = null
		},
		handleDifferentUser(value: any) {
			this.differentUser = value
		}
	},
	persist: { key: 'nex.profile', paths: ['user'], storage: localStorage }
})
