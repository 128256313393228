import { defineStore } from 'pinia'

interface AuthUser {
  access_token: any,
  refresh_token: any,
}

export const useAuthStore = defineStore({
	id: 'auth',
	state: () => ({
    authUser: null as AuthUser | null,
    expires: null as string | null
  }),
	getters: {
		getAuthUser: (state) => state.authUser || null
	},
	actions: {
		setAuthUser(payload: AuthUser | null) {
			this.authUser = payload
		},
		setExpires(payload: string) {
			this.expires = payload
		},
		clear() {
			this.authUser = null
			this.expires = null
		}
	},
	persist: { key: 'nex.auth', paths: ['authUser'], storage: localStorage }
})
