import { createRouter, createWebHistory, type RouteMeta } from 'vue-router'
import DashboardLayoutVue from '../layouts/dashboard.vue'
import { useAuthStore } from '@/stores/auth'
import { useProfileStore } from '@/stores/profile'
import { doLogout, getDecodeToken, getExpiredToken } from '../lib/auth'

interface IRouteMeta {
  title: string,
  auth?: boolean
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      redirect: '/home',
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/auth/ViewLogin.vue'),
      meta: {
        title: 'Login',
      } as RouteMeta & IRouteMeta,
    },
    {
      path: '/jadwal-pertandingan',
      name: 'jadwal-pertandingan',
      component: () => import('@/views/auth/LisKompetisi.vue'),
      meta: {
        title: 'Kompetisi',
      } as RouteMeta & IRouteMeta,
    },
    {
      path: '/jadwal-pertandingan/:id',
      name: 'jadwal-detail-pertandingan',
      component: () => import('@/views/auth/Pertandingan.vue'),
      meta: {
        title: 'Jadwal Pertandingan',
      } as RouteMeta & IRouteMeta,
    },
    {
      path: '/cetak-cocard/:id', //id kompetisi
      name: 'cetak-cocard',
      component: () => import('@/views/auth/CetakCocard.vue'),
      meta: {
        title: 'Cetak CoCard',
      } as RouteMeta & IRouteMeta,
    },
    {
      path: '/cetak-list-pemain/:id', //id kompetisi
      name: 'cetak-list-pemain',
      component: () => import('@/views/auth/CetakListPemain.vue'),
      meta: {
        title: 'Cetak List Pemain',
      } as RouteMeta & IRouteMeta,
    },
    {
      path: '/lineup/:id/:klubId', //id pertandingan
      name: 'cetak-line-up',
      component: () => import('@/views/auth/CetakLineup.vue'),
      meta: {
        title: 'Cetak Lineup',
      } as RouteMeta & IRouteMeta,
    },
    {
      path: '/403',
      name: 'forbidden',
      component: () => import('@/views/403.vue'),
      meta: {
        title: '403',
      } as RouteMeta & IRouteMeta,
    },
    {
      path: '/',
      component: DashboardLayoutVue,
      redirect: '/home',
      meta: {
        title: 'Beranda',
        auth: true,
        // roles: ['ADMIN_AFKAB', 'ADMIN_KLUB', 'ADMIN_KOMPETISI', 'ADMIN_APLIKASI']
      },
      children: [
        {
          path: 'home',
          name: 'home',
          component: () => import('@/views/dashboard/Home.vue'),
          meta: {
            title: 'Home',
            auth: true
          } as RouteMeta & IRouteMeta
        },
        {
          path: 'lis-klub',
          name: 'klub_index',
          component: () => import('@/views/dashboard/lis-klub/Index.vue'),
          meta: {
            title: 'List Klub',
            auth: true,
            roles: ['ADMIN_AFKAB', 'ADMIN_APLIKASI']
          } as RouteMeta & IRouteMeta
        },
        {
          path: 'tambah-klub',
          name: 'tambah_klub_index',
          component: () => import('@/views/dashboard/lis-klub/AddKlub.vue'),
          meta: {
            title: 'Daftarkan Klub',
            auth: true,
            roles: ['ADMIN_AFKAB', 'ADMIN_APLIKASI', 'ADMIN_KLUB']
          } as RouteMeta & IRouteMeta
        },
        {
          path: 'my-klub',
          name: 'my_klub_index',
          component: () => import('@/views/dashboard/my-klub/Index.vue'),
          meta: {
            title: 'My Klub',
            auth: true,
            roles: ['ADMIN_KLUB']
          } as RouteMeta & IRouteMeta
        },
        {
          path: 'klub/:id',
          name: 'detail_klub_index',
          component: () => import('@/views/dashboard/my-klub/Index.vue'),
          meta: {
            title: 'Detail Klub',
            auth: true,
            roles: ['ADMIN_AFKAB', 'ADMIN_APLIKASI', 'ADMIN_KLUB']
          } as RouteMeta & IRouteMeta
        },
        {
          path: 'anggota/:id',
          name: 'detail_anggota_index',
          component: () => import('@/views/dashboard/lis-anggota/DetailAnggota.vue'),
          meta: {
            title: 'Detail Anggota',
            auth: true,
            roles: ['ADMIN_AFKAB', 'ADMIN_APLIKASI', 'ADMIN_KLUB']
          } as RouteMeta & IRouteMeta
        },
        {
          path: 'klub/anggota/:id',
          name: 'detail_anggota_klub_index',
          component: () => import('@/views/dashboard/lis-anggota/AddAnggota.vue'),
          meta: {
            title: 'Tambah Anggota Klub',
            auth: true,
            roles: ['ADMIN_AFKAB', 'ADMIN_APLIKASI', 'ADMIN_KLUB']
          } as RouteMeta & IRouteMeta
        },
        {
          path: 'klub/anggota/:id/:anggotaId',
          name: 'edit_anggota_klub_index',
          component: () => import('@/views/dashboard/lis-anggota/AddAnggota.vue'),
          meta: {
            title: 'Edit Anggota Klub',
            auth: true,
            roles: ['ADMIN_AFKAB', 'ADMIN_APLIKASI', 'ADMIN_KLUB']
          } as RouteMeta & IRouteMeta
        },
        {
          path: 'kompetisi',
          name: 'kompetisi_index',
          component: () => import('@/views/dashboard/kompetisi/Index.vue'),
          meta: {
            title: 'Kompetisi',
            auth: true,
            roles: ['ADMIN_AFKAB', 'ADMIN_APLIKASI', 'ADMIN_KOMPETISI', 'ADMIN_KLUB']
          } as RouteMeta & IRouteMeta
        },
        {
          path: 'kompetisi/:id',
          name: 'detail_kompetisi_index',
          component: () => import('@/views/dashboard/detail-kompetisi/Index.vue'),
          meta: {
            title: 'Detail Kompetisi',
            auth: true,
            roles: ['ADMIN_AFKAB', 'ADMIN_APLIKASI']  //'ADMIN_KOMPETISI'
          } as RouteMeta & IRouteMeta
        },
        {
          path: 'pertandingan/:id',
          name: 'kelola_pertandingan_index',
          component: () => import('@/views/dashboard/detail-kompetisi/kelola-pertandingan.vue'),
          meta: {
            title: 'Kelola Pertandingan',
            auth: true,
            roles: ['ADMIN_AFKAB', 'ADMIN_APLIKASI', 'ADMIN_KOMPETISI', 'ADMIN_KLUB']
          } as RouteMeta & IRouteMeta
        },
        {
          path: 'lis-anggota',
          name: 'anggota_index',
          component: () => import('@/views/dashboard/lis-anggota/Index.vue'),
          meta: {
            title: 'Lis Anggota Klub',
            auth: true,
            roles: ['ADMIN_KLUB']
          } as RouteMeta & IRouteMeta
        },
        {
          path: 'tambah-anggota',
          name: 'tambah-anggota_index',
          component: () => import('@/views/dashboard/lis-anggota/AddAnggota.vue'),
          meta: {
            title: 'Tambah Anggota Klub',
            auth: true,
            roles: ['ADMIN_AFKAB', 'ADMIN_APLIKASI']
          } as RouteMeta & IRouteMeta
        },
        {
          path: 'user',
          name: 'user_index',
          component: () => import('@/views/dashboard/user/Index.vue'),
          meta: {
            title: 'User',
            auth: true,
            roles: ['ADMIN_AFKAB', 'ADMIN_APLIKASI']
          } as RouteMeta & IRouteMeta
        },
        {
          path: 'pengurus-afkab',
          name: 'pengurus_afkab_index',
          component: () => import('@/views/dashboard/pengurus-afkab/Index.vue'),
          meta: {
            title: 'Pengurus Afkab',
            auth: true,
            roles: ['ADMIN_AFKAB', 'ADMIN_APLIKASI', 'ADMIN_KLUB', 'ADMIN_KOMPETISI']
          } as RouteMeta & IRouteMeta
        }
      ],
    },
    {
      path: '/:pathMatch(.*)',
      name: 'not-found',
      component: () => import('@/views/404.vue'),
      meta: {
        title: 'Page Not Found',
      } as RouteMeta & IRouteMeta,
    },
  ],
  scrollBehavior() {
		return { top: 0, left: 0, behavior: 'smooth' }
	}
});

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title as string;

  if (from.meta?.layout && to.name === 'not-found') {
		to.meta.name = to.name
	} else {
		delete to.meta.name
	}
	const authStore = useAuthStore()
  const profileStore = useProfileStore()
  const user = profileStore?.user?.user
	const isAuthRequired = to.meta.auth || false
	let isLoggedIn = false

	const jwtToken = (await authStore.getAuthUser?.access_token) || null
	const parsedToken = getDecodeToken(jwtToken)
  
	if ((isAuthRequired || ['/login'].includes(to.path)) && parsedToken) {
    const parsedTokenValid = getExpiredToken(parsedToken)
		isLoggedIn = !parsedTokenValid?.expired
	}

  // Periksa role user
  if (to.meta.roles && !to.meta?.roles?.includes(user?.role)) {
    return next({ name: 'forbidden' });
  }

	if (isAuthRequired) {
		if (!isLoggedIn) {
      await doLogout()
      return next('/login')
    } 
	}
	if (['/login'].includes(to.path) && isLoggedIn) return next('/')
	next()
})

export default router
