<script setup lang="ts">
const props = defineProps({
  title: String,
});
</script>

<template>
  <div class="flex items-center flex-row justify-between">
    <h1 v-if="props.title" class="text-2xl font-semibold">
      {{ title }}
    </h1>
    <slot></slot>
  </div>
</template>